.hero-img{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    position: relative;
}
.hero-img::before{
    content: "";
 
    background: url("https://images.unsplash.com/photo-1521185496955-15097b20c5fe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHByb2dyYW1taW5nfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60");
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position:absolute;
    top: 0;
    left: 0;
    z-index: -1;

}
.heading{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.hero-img h1{

    font-size: 2.4rem;
}

.hero-img p{

    font-size: 1.4rem;
    text-align: center;

}

@media screen and (max-width: 640px){
    .hero-img h1{

        font-size: 2rem;
    }
}