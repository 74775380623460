.mask{
    width: 100vw;
    height: 90vh;
    position:relative;
}

.into-img{
    width: 90vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    position: fixed;
}

.mask::after{
    content:"";
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.3;
}

.hero{
    height: 100vw;
    width: 100%;
    position: relative;
}

.hero .content{
    position: absolute;
    transform: translate(-50%, -20%);
    top: 50%;
    left: 49%;
    text-align: center;

}
.hero .content h1{
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
}

.hero .content p{
    font-size: 1.4rem;
    font-weight: 200;
    text-transform: uppercase;
}

.content .btn{
    margin: 1rem 0.7rem;
}

@media screen and(max-width:640px) {
    .hero .content h1{
        font-size: 3rem;
        padding: 0.6rem 0 1.5rem;
    }
    
    .hero .content p{
        font-size: 1.4rem;
  
    }
    
    .content .btn{
        margin: 1rem 0.7rem;
    }
}