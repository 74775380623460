.footer{
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 6rem 0;
    background-color: rgba(19,19,19,0.8);
}

.footer-container{

    max-width: 1140px;
    margin: auto;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px

}

.left{
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;

}

.location{
    display: flex;
    margin-bottom: 0.8rem;

}

.location p{

    line-height: 30px;

}

.right{
    height:100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;

}

.social{
    margin-top: 1rem;

}

.Center{
    font-size: 0.9rem;
    flex-direction: row;
    
    width: 50%;
    margin: auto;

}

.col-sm{
    font-size: 0.9rem;
    flex-direction: row;
   
   

}

h4{
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
}
p{
    font-size: 1.2rem;
}

@media screen and (max-width:640px) {
    .footer-container{

        grid-template-columns:  1fr;
       
    
    }
}