
  @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@1,200&family=Outfit:wght@200;300;400;600;700&family=Source+Code+Pro:ital@1&display=swap');

  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Outfit', sans-serif

  }
  body{
    background: black;
    overflow-x: hidden;
  }

  h1,h4,p,a{
    color:white;
    text-decoration: none;
  }

  ul{
    list-style-type: none;
  }

  .btn{
    padding: 12px 32px;
    font-size: 1rem;
    text-transform: uppercase;
    background: yellowgreen;
    color: #222;
    border: 1px solid #fff;
    font-weight: 600;
    cursor: pointer;
  }

  .btn-light{
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    font-weight: 600;
    cursor: pointer;
    padding: 12px 32px;
    cursor: pointer;
    font-size: 1rem;

  }

  .btn:hover{
    background: rgba(255,255,255,0.2);
    color: #fff;
    transition: 0.3s;
  }



